// stylelint-disable property-blacklist, declaration-no-important

//
// Border
//
.border,.border-0        { border: 0 !important; }
.border-top,.border-top-0    { border-top: 0 !important; }
.border-right,.border-right-0  { border-right: 0 !important; }
.border-bottom,.border-bottom-0 { border-bottom: 0 !important; }
.border-left,.border-left-0   { border-left: 0 !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}


.rounded{border-radius:0 !important;}
