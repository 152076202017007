// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: $nav-divider-color, $margin-y: $nav-divider-margin-y, $ignore-warning: false) {
  height: 0;
  margin: (6/14 * $line-height) (-6/16 * $space-width) (7/14 * $line-height) (-5/16 * $space-width);
  overflow: hidden;
  border-top: 1px solid $color;
}
