@mixin badge-variant($bg) {
  color: brighten($bg);
  background-color: transparent;
  &:before {
    color: $bg;
    content: "[";
  }
  &:after {
    color: $bg;
    content: "]";
  }

  @at-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg);
    }

    &:focus,
    &.focus {
      outline: 0;
    }
  }
}
