// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
}

